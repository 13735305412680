<script setup>
const props = defineProps({
  color: String,
  textColor: String
})
</script>

<template>
  <div class="flex justify-center items-center gap-2.5 rounded px-0 py-1 w-full" :style="{'background-color': props.color}">
    <div class="text-[9px] leading-3 uppercase font-medium text-center "
         :style="{'color': textColor || 'white'}">
      <slot />
    </div>
  </div>
</template>
